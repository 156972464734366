

 <!-- <div class="imageLogo">
    <img src="../../../assets/imgs/feedback-banner.jpg" alt="" class="bg-image">
</div> -->
<div class="login-container">
   
    <div class="card">
        
        
        <h4 class="card-header text-center"> <b> Reset Password </b></h4>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username"> <b> Username </b></label>
                    <input type="text" formControlName="username" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.username.errors }"  (change)="usernameChange($event)" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
    
                <div class="form-group">
                    <label for="password"> <b> Password </b></label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="confirmPassword"> <b>Confirm Password</b> </label>
                    <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                    </div>
                </div>

                
                <div class="form-group text-center">
                    <button [disabled]="loading" class="btn btn-success" >
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                </div>
                <div class="text-center">
                    <a routerLink="" class="btn btn-link">Click here to login</a>
                </div>
    
            </form>
        </div>
    </div>
    </div>