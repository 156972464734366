import { Component, OnInit } from '@angular/core';
import { CustomerFeedbackService } from '../../customer-feedback.service';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.scss']
})
export class CustomerFeedbackComponent implements OnInit {

  curt_date: any = new Date().toISOString().split("T")[0];

  // dropdownList : any  [] = [];
  // selectedItems = [];
  // dropdownSettings = {};
  dropdownList: Array<any> = [];
  preComList: Array<any> = [];

  selectedPrecom: any;

  selectedItems = [];
  dropdownSettings = {};
  dropdownSettingsPrecom = {};

  aboutVal: any[] = [];
  precomVal: any[] = [];

  templogRecord: any[] = [];
  expanded: boolean = false;
  allCountries: any;
  country: any;
  cty_code: any;
  l_gender: any;
  l_status: any;
  gender_female: any;
  gender_male: any;
  dob_date: any;
  wedDate: any;
  newCustomer: any;
  countryFull: any;
  userCode: any;
  emailRegex: any;
  emailformat: any;
  userClass: any;
  mobileClass: any;
  emailClass: any;
  codeClass: any;
  mobClass: any;
  dobClass: any;
  wedClass: any;

  bDay: any = 1;
  bMonth: any = 1;
  wDay: any = 1;
  wMonth: any = 1;
  allNationality: any;
  maxdate: any;
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  branch: any;



  customerFeedback = this.formBuilder.group({
    country: ['', [Validators.required]],
    salutation: ['', [Validators.required]],
    username: ['', [Validators.required]],
    mobile: ['', [Validators.required]],
    email: ['', [Validators.required]],
    occupation: ['', [Validators.required]],
    birth_date: ['', [Validators.required]],
    wed_date: ['', [Validators.required]],
    about: ['', [Validators.required]],
    looking_for: ['', [Validators.required]],
    reason_non_select: ['', [Validators.required]],
    detailed_remark: ['', [Validators.required]],
    shop_exe: ['', [Validators.required]],
    showroom_access: ['', [Validators.required]],
    product_range: ['', [Validators.required]],
    quality_design: ['', [Validators.required]],
    showroom_amb: ['', [Validators.required]],
    staff_behaviour: ['', [Validators.required]],
    overall_service: ['', [Validators.required]],
    suggess_impro: ['', [Validators.required]],
    tel1: ['', [Validators.required]],
    gender: ['', [Validators.required]],
    marital_status: ['', [Validators.required]],
    nationality: ['', [Validators.required]],
    pre_com: ['', [Validators.required]],
    telcode: ['', [Validators.required]],
    bday: ['', [Validators.required]],
    bmon: ['', [Validators.required]],
    wday: ['', [Validators.required]],
    wmon: ['', [Validators.required]],




  });



  constructor(
    private suntechCustomerFb: CustomerFeedbackService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private datePipe: DatePipe,


  ) {


    this.branch = localStorage.getItem('branchCode');
    if (this.branch != "" || this.branch != null || this.branch != undefined)
      this.branch = this.branch
    else
      this.branch = "";
    console.log(this.branch)

    // alert(this.curt_date);
    this.userCode = ' ';
    this.getCountry();
    this.getNationality();
    this.customerFeedback.controls['gender'].setValue('Male');
    this.customerFeedback.controls['marital_status'].setValue('Single');
    this.customerFeedback.controls['quality_design'].setValue('True');
    this.customerFeedback.controls['shop_exe'].setValue('True');
    this.customerFeedback.controls['looking_for'].setValue('True');
    this.customerFeedback.controls['salutation'].setValue('Mr.');

    // this.customerFeedback.controls['bday'].setValue(1);
    // this.customerFeedback.controls['wday'].setValue(1);
    // this.customerFeedback.controls['bmon'].setValue(1);
    // this.customerFeedback.controls['wmon'].setValue(1);
    // this.customerFeedback.controls['about'].setValue('');


    this.dropdownList = [
      { item_id: 'TV', item_text: 'Television' },
      { item_id: 'RA', item_text: 'Radio' },
      { item_id: 'NE', item_text: 'Print Ad' },
      { item_id: 'SM', item_text: 'Social Media' },
      { item_id: 'HO', item_text: 'Hoarding' },
      { item_id: 'FF', item_text: 'Friends Or Relative' },
      { item_id: 'ON', item_text: 'Online' },
      { item_id: 'OU', item_text: 'Outdoor' },
      { item_id: 'OT', item_text: 'Others' },
    ];


    this.preComList = [
      { item_id: 'EM', item_text: 'Email' },
      { item_id: 'WH', item_text: 'WhatsApp' },
      { item_id: 'SM', item_text: 'SMS' },
      { item_id: 'PH', item_text: 'Telephone' },
      { item_id: 'NO', item_text: 'No' }
    ];
    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    this.dropdownSettings = {
      // this.dropdownSettings:IDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: false
    };

    this.dropdownSettingsPrecom = {
      // this.dropdownSettings:IDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: false
    };




    // this.l_gender = 'Male';
    // this.gender_male= 'checked';


  }
  clickSame(event) {
    console.log(event.target.checked)
    var value = event.target.checked;
    if (value == true) {

      this.customerFeedback.controls['tel1'].setValue(this.customerFeedback.value.mobile);
      this.customerFeedback.controls['telcode'].setValue(this.customerFeedback.value.country);

    } else {
      this.customerFeedback.controls['tel1'].setValue('');
      this.customerFeedback.controls['telcode'].setValue('');

    }
  }

  onAboutDeSelectAll(item: any) {
    console.log(item)
    this.aboutVal = []

  }
  onAboutDeSelect(item: any) {
    this.aboutVal = this.aboutVal.filter(items => items !== item.item_id)
  }


  onDeSelectAll(item: any) {
    console.log(item)
    this.precomVal = []

  }
  onDeSelect(item: any) {
    this.precomVal = this.precomVal.filter(items => items !== item.item_id)
  }

  onPreComItemSelect(item: any) {
    // console.log(this.precomVal.length)
    if (this.precomVal.length == 5) {
      this.precomVal = []
    }

    // console.log(item);
    // this.precomVal = item;
    // var result = item.map(function(a) {return a.item_id;});
    if (this.precomVal.includes(item.item_id) != true)
      this.precomVal.push(item.item_id);
  }
  onPreComSelectAll(items: any) {
    this.precomVal = []
    console.log(items);
    var result = items.map(function (a) { return a.item_id; });
    this.precomVal = result;

  }

  onItemSelect(item: any) {
    if (this.aboutVal.length == 9) {
      this.aboutVal = []
    }
    console.log(item);
    // var result = item.map(function(a) {return a.item_id;});
    // this.aboutVal = result;
    this.aboutVal.push(item.item_id);

  }
  onSelectAll(items: any) {
    console.log(items);
    this.aboutVal = []
    var result = items.map(function (a) { return a.item_id; });
    this.aboutVal = result;

  }
  ngOnInit(): void {


    // screen.lockOrientation('landscape');
    var inputBox = document.getElementById("mobile");

    var invalidChars = [
      "-",
      "+",
      "e",
      "f",

    ];

    var dtToday = new Date();

    var _month = dtToday.getMonth() + 1;
    var _day = dtToday.getDate();
    var _year = dtToday.getFullYear();
    //var __month="";
    //var __day="";

    /*if(_month < 10)
        _month = '0' + _month.toString();
    if(_day < 10)
        _day = '0' + _day.toString();*/

    this.maxdate = _year + '-' + _month + '-' + _day;
    //$('#txtDate').attr('max', maxDate);

    inputBox.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });


    function stopRKey(evt) {
      var evt = (evt) ? evt : ((event) ? event : null);
      var node = (evt.target) ? evt.target : ((evt.srcElement) ? evt.srcElement : null);
      if ((evt.keyCode == 13)) { return false; }
    }

    document.onkeypress = stopRKey;

    // var maxDate = 31
    //     document.querySelector(".maxDate")
    //       .addEventListener("keypress", function(e) {
    //         e.preventDefault();
    //         var input = e.target;
    //         var value = Number(input.value);
    //         var key = Number(e.key);
    //         if (Number.isInteger(key)) {
    //           value = Number("" + value + key);
    //           if (value > maxValue) {
    //             return false;
    //           }
    //           input.value = value;
    //         }
    //       });

  }

  getCountry() {
    this.suntechCustomerFb.getCountry()
      .subscribe((res) => {
        if (res.status == 'Success') {
          let result = res.response;
          // console.log(result)
          this.allCountries = result;
          if (result == null) {
            this.toastr.error('Server issue please wait. Please reload a page', 'Network !', {
              timeOut: 5000,
            });
          }
        } else {

        }
      });
  }
  getNationality() {
    this.suntechCustomerFb.getNationality()
      .subscribe((res) => {
        if (res.status == 'Success') {
          let result = res.response;
          console.log(result)
          this.allNationality = result;
        } else {

        }
      });
  }
  // countryNation(event){

  // }
  countryChanage(event) {
    // var value;
    // // localStorage.setItem('mobileNo', event.target.value)
    // if(isNaN(event.target.value) == false){
    //    value = this.allCountries.find(i => i.MOBILECOUNTRYCODE == event.target.value);
    // this.countryFull = value['DESCRIPTION'];
    // console.log( value['DESCRIPTION'])

    // }else{
    //   var values =  event.target.value;
    //   values = values.toLowerCase();
    //   // values = values.charAt(0).toUpperCase();
    //   const nameCapitalized = values.charAt(0).toUpperCase() + values.slice(1)

    //   value = this.allCountries.find(i => i.DESCRIPTION == nameCapitalized);

    //   this.cty_code = value['MOBILECOUNTRYCODE'];
    //  this.customerFeedback.controls['country'].setValue( value['MOBILECOUNTRYCODE']);
    //  console.log( value['MOBILECOUNTRYCODE'])

    // }

    // console.log(event.target)
    let value = this.allCountries.find(i => i.MOBILECOUNTRYCODE == event.target.value);
    // console.log(value)
    this.countryFull = value['DESCRIPTION'];

    // console.log(event.target.name)
    // console.log(event.target.value)
    // console.log(event.target.getAttribute('data-id'))
    // console.log(this.customerFeedback.value.country);
    this.cty_code = this.customerFeedback.value.country;
    // console.log(event.target.value)
    // console.log(value)

    // console.log(event.target.name)
    // console.log(event.target.value)
    // console.log(event.target.getAttribute('data-id'))
    // console.log(this.customerFeedback.value.country);
  }

  // }
  chanageCountryCode(event) {

    //  var res=  this.allCountries.filter( obj => Object.keys(obj).some( key => key.indexOf(event.target.value) > -1 ) )
    // console.log(res)
    // var res= this.allCountries.filter(obj => Object.keys(obj).some(key => obj[key].includes(event.target.value)));

    // console.log(res)
    // let obj = this.allCountries.filter(o => o.MOBILECOUNTRYCODE == event.target.value);

    // console.log(isNaN(values))


    // console.log(event.target)
    let value = this.allCountries.find(i => i.MOBILECOUNTRYCODE == event.target.value);
    // console.log(value)
    this.countryFull = value['DESCRIPTION'];

    // console.log(event.target.name)
    // console.log(event.target.value)
    // console.log(event.target.getAttribute('data-id'))
    // console.log(this.customerFeedback.value.country);
    this.cty_code = this.customerFeedback.value.country;
    // var value;
    // // localStorage.setItem('mobileNo', event.target.value)
    // if(isNaN(event.target.value) == false){
    //     value = this.allCountries.find(i => i.MOBILECOUNTRYCODE == event.target.value);
    // this.countryFull = value['DESCRIPTION'];
    // console.log( value['DESCRIPTION'])

    // }else{
    //   var values =  event.target.value;
    //   values = values.toLowerCase();
    //   // values = values.charAt(0).toUpperCase();
    //   const nameCapitalized = values.charAt(0).toUpperCase() + values.slice(1)

    //   value = this.allCountries.find(i => i.DESCRIPTION == nameCapitalized);

    //   this.cty_code = value['MOBILECOUNTRYCODE'];
    //   this.customerFeedback.controls['telcode'].setValue( value['MOBILECOUNTRYCODE']);
    //   console.log( value['MOBILECOUNTRYCODE'])

    // }
  }
  countryCodeTel(event) {
    var values = event.target.value;

    var isString = isNaN(values);
    var results = [];
    // this.allCountries

    for (let i = 0; i < this.allCountries.length; i++) {
      if (isString == false) {

        if (this.allCountries[i].MOBILECOUNTRYCODE.includes(values) != false) {
          results.push(this.allCountries[i].MOBILECOUNTRYCODE);
        }
      } else {
        values = values.toLowerCase();
        // values = values.charAt(0).toUpperCase();
        const nameCapitalized = values.charAt(0).toUpperCase() + values.slice(1)

        console.log(nameCapitalized)
        if (this.allCountries[i].DESCRIPTION.includes(nameCapitalized) != false) {
          results.push(this.allCountries[i].DESCRIPTION);
        }
      }

    }
    console.log(results)
    if (results.length == 0) {
      this.customerFeedback.controls['telcode'].setValue('');
    }
  }

  changeName() {
    if (this.customerFeedback.value.username == "") {
      this.userClass = "is-invalid";
    } else {
      this.userClass = "";

    }

  }
  changeCode() {
    if (this.customerFeedback.value.country == "") {
      this.codeClass = "is-invalid";
    } else {
      this.codeClass = "";

    }
  }
  changeEmail() {

    /* if (this.customerFeedback.value.email == "") {
       this.emailClass = "is-invalid";
     } else {
       this.emailClass = "";

     } */
  }
  changeDob() {

    if (this.customerFeedback.value.birth_date == "") {
      this.dobClass = "is-invalid";
    } else {
      this.dobClass = "";

    }
  }
  getCustomerDetails(event) {
    // if (this.customerFeedback.value.mobile == "") {
    //   this.mobClass = "is-invalid";
    // } else {
    //   this.mobClass = "";

    // }
    // //  localStorage.setItem('mobileNo',event.target.value)
    // //  var old_Mobile = localStorage.getItem('mobileNo')

    // //   var mobile = this.customerFeedback.value.mobile;
    // //   if(old_Mobile != mobile){

    //   this.suntechCustomerFb.getCustomerDetails(mobile)
    //     .subscribe((result) => {

    //       console.log(result)
    //       if (result != "" && result != null) {
    //         console.log("getting not null");
    //         this.newCustomer = false;
    //         result = result[0];
    //         this.userCode = result['CODE'];
    //         this.customerFeedback.controls['country'].setValue(result['MOBILECODE1']);
    //         // this.customerFeedback.controls['country'].setValue(result['COUNTRY_CODE']);

    //         this.cty_code = result['MOBILECODE1'];
    //         // this.cty_code = result['COUNTRY_CODE'];
    //         this.customerFeedback.controls['username'].setValue(result['NAME']);
    //         this.customerFeedback.controls['email'].setValue(result['EMAIL']);
    //         this.customerFeedback.controls['occupation'].setValue(result['OCCUPATION']);
    //         // this.customerFeedback.controls['birth_date'].setValue(new Date(result['LAST_UPDATED_DATE']));
    //         this.dob_date = new Date(result['DATE_OF_BIRTH']);
    //         this.bDay = this.dob_date.getDate();
    //         this.bMonth = this.dob_date.getMonth() + 1;

    //         this.dob_date = this.dob_date.toISOString().split('T')[0];

    //         this.customerFeedback.controls['birth_date'].setValue(this.dob_date);

    //         this.wedDate = new Date(result['WED_DATE']);
    //         this.wDay = this.wedDate.getDate();
    //         this.wMonth = this.wedDate.getMonth() + 1;
    //         this.wedDate = this.wedDate.toISOString().split('T')[0];

    //         this.customerFeedback.controls['wed_date'].setValue(this.wedDate);
    //         this.customerFeedback.controls['tel1'].setValue(result['WUPMOBILENO']);
    //         this.customerFeedback.controls['gender'].setValue(result['GENDER']);
    //         this.customerFeedback.controls['telcode'].setValue(result['WUPMOBILECODE']);
    //         this.customerFeedback.controls['marital_status'].setValue(result['MARITAL_ST']);
    //         // console.log( this.customerFeedback.value.marital_status);
    //       } else {
    //         this.newCustomer = true;
    //         console.log("getting null");
    //         // this.userCode = '';
    //         // this.customerFeedback.controls['country'].setValue('');
    //         // this.cty_code = '';
    //         // this.customerFeedback.controls['username'].setValue('');
    //         // this.customerFeedback.controls['email'].setValue('');
    //         // this.customerFeedback.controls['occupation'].setValue('');
    //         // this.customerFeedback.controls['birth_date'].setValue('');
    //         // this.customerFeedback.controls['wed_date'].setValue('');
    //         // this.customerFeedback.controls['tel1'].setValue('');
    //         // this.customerFeedback.controls['gender'].setValue('');
    //         // this.customerFeedback.controls['marital_status'].setValue('');

    //         this.userCode = '';
    //         //this.customerFeedback.controls['country'].setValue('');
    //         this.cty_code = '';
    //         this.customerFeedback.controls['username'].setValue('');
    //         this.customerFeedback.controls['email'].setValue('');
    //         this.customerFeedback.controls['occupation'].setValue('');
    //         this.customerFeedback.controls['birth_date'].setValue('');
    //         this.customerFeedback.controls['wed_date'].setValue('');
    //         this.customerFeedback.controls['tel1'].setValue('');
    //         this.customerFeedback.controls['gender'].setValue('');
    //         this.customerFeedback.controls['marital_status'].setValue('');
    //       }
    //     });
    //   // }

    if (this.customerFeedback.value.mobile == "") {
      this.mobileClass = "is-invalid";
    } else {
      this.mobileClass = "";

    }
  }
  changeGender(event) {
    console.log(event.target.value)
  }
  numberChange(event) {
    //   localStorage.setItem('mobileNo', event.target.value)
    //  console.log(localStorage.getItem('mobileNo'))
    // if(event.target.value== ''){
    // this.userCode = '';
    // //this.customerFeedback.controls['country'].setValue('');
    // this.cty_code = '';
    // this.customerFeedback.controls['username'].setValue('');
    // this.customerFeedback.controls['email'].setValue('');
    // this.customerFeedback.controls['occupation'].setValue('');
    // this.customerFeedback.controls['birth_date'].setValue('');
    // this.customerFeedback.controls['wed_date'].setValue('');
    // this.customerFeedback.controls['tel1'].setValue('');
    // this.customerFeedback.controls['gender'].setValue('');
    // this.customerFeedback.controls['marital_status'].setValue('');
    // }

    //  localStorage.setItem('mobileNo',event.target.value)
    //  var old_Mobile = localStorage.getItem('mobileNo')

    var mobile = this.customerFeedback.value.mobile;
    //   if(old_Mobile != mobile){

    this.suntechCustomerFb.getCustomerDetails(mobile)
      .subscribe((res) => {
        if (res.status == 'Success' && res != "" && res != null) {
          let result = res.response;
          console.log(result)
            console.log("getting not null");
            this.newCustomer = false;
            result = result[0];
            this.userCode = result['CODE'];
            this.customerFeedback.controls['country'].setValue(result['MOBILECODE1']);
            // this.customerFeedback.controls['country'].setValue(result['COUNTRY_CODE']);

            this.cty_code = result['MOBILECODE1'];
            // this.cty_code = result['COUNTRY_CODE'];
            this.customerFeedback.controls['username'].setValue(result['NAME']);
            this.customerFeedback.controls['email'].setValue(result['EMAIL']);
            this.customerFeedback.controls['occupation'].setValue(result['OCCUPATION']);
            this.customerFeedback.controls['marital_status'].setValue(result['MARITAL_ST']);

            // this.customerFeedback.controls['birth_date'].setValue(new Date(result['LAST_UPDATED_DATE']));
            this.dob_date = new Date(result['DATE_OF_BIRTH']);
            console.log('bday', this.dob_date)
            this.bDay = this.dob_date.getDate();
            console.log('bday', this.bDay)

            this.bMonth = this.dob_date.getMonth() + 1;
            console.log('bMonth', this.bMonth)
            this.customerFeedback.controls['bday'].setValue(this.bDay);
            this.customerFeedback.controls['bmon'].setValue(this.bMonth);

            this.dob_date = new Date(result['DATE_OF_BIRTH']);
            this.dob_date.setDate(this.bDay + 1);
            this.dob_date = this.dob_date.toISOString().split('T')[0];
            console.log('bday', this.dob_date)

            // this.dob_date.setDate(this.bDay + 1);

            this.customerFeedback.controls['birth_date'].setValue(this.dob_date);

            this.wedDate = new Date(result['WED_DATE']);
            this.wDay = this.wedDate.getDate();
            console.log(this.wDay)

            this.wMonth = this.wedDate.getMonth() + 1;

            this.customerFeedback.controls['wday'].setValue(this.wDay);
            this.customerFeedback.controls['wmon'].setValue(this.wMonth);


            this.wedDate = new Date(result['WED_DATE']);
            this.wedDate.setDate(this.wDay + 1);
            this.wedDate = this.wedDate.toISOString().split('T')[0];

            this.customerFeedback.controls['wed_date'].setValue(this.wedDate);
            this.customerFeedback.controls['tel1'].setValue(result['WUPMOBILENO']);
            this.customerFeedback.controls['gender'].setValue(result['GENDER']);
            this.customerFeedback.controls['telcode'].setValue(result['WUPMOBILECODE']);
            this.customerFeedback.controls['nationality'].setValue(result['NATIONALITY']);
            // this.selectedItems = result['SOURCE'].split('#')
            var abt = result['SOURCE']?.split('#')
            var pref = result['PREFERENCE_CONTACT']?.split('#')
            // console.log(abt)
            // console.log(this.selectedItems)

            var results = this.dropdownList.filter(function (obj) {
              return abt?.some(function (obj2) {
                return obj.item_id == obj2;
              });
            });
            this.customerFeedback.controls['about'].setValue(results);

            var results = this.preComList.filter(function (obj) {
              return pref?.some(function (obj2) {
                return obj.item_id == obj2;
              });
            });
            this.customerFeedback.controls['pre_com'].setValue(results);

            // var results= this.dropdownList.filter(items => items ==  this.selectedItems)

            // const results = this.dropdownList.filter(({ item_id: id1 }) => this.selectedItems.some(({ id2 }) => id2 == id1));
            // var results =  this.dropdownList.concat(this.selectedItems);


            console.log(results);
            // this.selectedItems = [
            //   { item_id: 'TV', item_text: 'Television' },
            //   { item_id: 'RA', item_text: 'Radio' },
            // ];
            // this.customerFeedback.controls['about'].setValue(this.selectedItems);
            // console.log(this.customerFeedback.value.about)

            // SOURCE
            // console.log( this.customerFeedback.value.marital_status);
          } else {
            this.newCustomer = true;
            console.log("getting null");
            // this.userCode = '';
            // this.customerFeedback.controls['country'].setValue('');
            // this.cty_code = '';
            // this.customerFeedback.controls['username'].setValue('');
            // this.customerFeedback.controls['email'].setValue('');
            // this.customerFeedback.controls['occupation'].setValue('');
            // this.customerFeedback.controls['birth_date'].setValue('');
            // this.customerFeedback.controls['wed_date'].setValue('');
            // this.customerFeedback.controls['tel1'].setValue('');
            // this.customerFeedback.controls['gender'].setValue('');
            // this.customerFeedback.controls['marital_status'].setValue('');

            this.userCode = '';
            //this.customerFeedback.controls['country'].setValue('');
            this.cty_code = '';
            this.customerFeedback.controls['username'].setValue('');
            this.customerFeedback.controls['email'].setValue('');
            this.customerFeedback.controls['occupation'].setValue('');
            this.customerFeedback.controls['birth_date'].setValue('');
            this.customerFeedback.controls['wed_date'].setValue('');
            this.customerFeedback.controls['tel1'].setValue('');
            this.customerFeedback.controls['gender'].setValue('');
            this.customerFeedback.controls['marital_status'].setValue('');
            this.bDay = '';
            this.bMonth = '';
            this.wDay = '';
            this.wMonth = '';
            this.customerFeedback.controls['nationality'].setValue('');
            this.customerFeedback.controls['about'].setValue('');
            this.customerFeedback.controls['pre_com'].setValue('');
            this.customerFeedback.controls['telcode'].setValue('');

          }


      });
    // }

    if (this.customerFeedback.value.mobile == "") {
      this.mobileClass = "is-invalid";
    } else {
      this.mobileClass = "";

    }
  }

  parseI(event) {
    console.log(event.target.value)
    var value = event.target.value;
    console.log(value.charAt(0))
    if (value.charAt(0) == 0) {
      let val = value.slice(1);
      console.log(val)
      this.customerFeedback.controls['mobile'].setValue(parseInt(val))
    }
  }

  parseW(event) {
    console.log(event.target.value)
    var value = event.target.value;
    console.log(value.charAt(0))
    if (value.charAt(0) == 0) {
      let val = value.slice(1);
      console.log(val)
      this.customerFeedback.controls['tel1'].setValue(parseInt(val))
    }
  }

  lookingForOnchange(event) {

  }
  birthChange(event) {
    var date = new Date((event.target.value));
    this.bDay = date.getDate();
    this.bMonth = date.getMonth() + 1;
  }
  wedChange(event) {
    var date = new Date((event.target.value));
    this.wDay = date.getDate();
    this.wMonth = date.getMonth() + 1;
  }
  bDayChange(event) {
    var value = event.target.value;
    var min = 1;
    var max = 31;
    if (parseInt(value) < min || isNaN(parseInt(value))) {
      this.customerFeedback.controls['bday'].setValue(min);
      value = min;
    }
    else if (parseInt(value) > max) {
      this.customerFeedback.controls['bday'].setValue(max);
      value = max;
    }
    else value = value;

    if (this.customerFeedback.value.birth_date == '') {

      this.dob_date = new Date();
      this.dob_date.setDate(value);
      this.dob_date = this.dob_date.toISOString().split('T')[0];
      this.customerFeedback.controls['birth_date'].setValue(this.dob_date);
    } else {
      var dob_date = new Date(this.customerFeedback.value.birth_date);
      dob_date.setDate(value);
      this.dob_date = dob_date.toISOString().split('T')[0];
      this.customerFeedback.controls['birth_date'].setValue(this.dob_date);
    }



    // var keynum;
    // if (window.event) { //IE
    //     keynum = event.keyCode;
    // } else if (event.which) {
    //     keynum = event.which;
    // } if (event.target.value !== undefined) {
    //     // console.info("hour text box Value : " + event.target.value);
    //     console.info("Charcter key press value : " + String.fromCharCode(keynum));

    //     var currentValue = parseInt(event.target.value) + String.fromCharCode(keynum);

    //     console.info("Combine value :" + currentValue);
    //     var currentValueInt = parseInt(currentValue);
    //     if (currentValueInt > 31) {
    //         console.info("Then Result is :" + currentValueInt);
    //         event.preventDefault();
    //         return false;
    //     }
    // } else {
    //   event.preventDefault();
    //     return false;
    // }

  }
  bMonthChange(event) {
    var value = event.target.value;
    var min = 1;
    var max = 12;
    if (parseInt(value) < min || isNaN(parseInt(value))) {
      this.customerFeedback.controls['bmon'].setValue(min);
      value = min;
    }
    else if (parseInt(value) > max) {
      this.customerFeedback.controls['bmon'].setValue(max);
      value = max;
    }
    else value = value;
    if (this.customerFeedback.value.birth_date == '') {

      this.dob_date = new Date();
      this.dob_date.setMonth(value - 1);
      this.dob_date = this.dob_date.toISOString().split('T')[0];
      this.customerFeedback.controls['birth_date'].setValue(this.dob_date);
    } else {
      var dob_date = new Date(this.customerFeedback.value.birth_date);
      dob_date.setMonth(value - 1);
      this.dob_date = dob_date.toISOString().split('T')[0];
      this.customerFeedback.controls['birth_date'].setValue(this.dob_date);
    }


  }

  wDayPress(e) {
    // var maxValue=31;
    //  var input = e.target;
    //     var value = Number(input.value);
    //     var key = Number(e.key);
    //     if (Number.isInteger(key)) {
    //       value = Number("" + value + key);
    //       if (value > maxValue) {
    //         return false;
    //       }
    //       input.value = value;
    //     }
  }
  wDayChange(event) {

    var value = event.target.value;
    var min = 1;
    var max = 31;
    if (parseInt(value) < min || isNaN(parseInt(value))) {
      this.customerFeedback.controls['wday'].setValue(min);
      value = min;
    }
    else if (parseInt(value) > max) {
      this.customerFeedback.controls['wday'].setValue(max);
      value = max;
    }
    else value = value;

    if (this.customerFeedback.value.wed_date == '') {

      this.wedDate = new Date();
      this.wedDate.setDate(event.target.value);
      this.wedDate = this.wedDate.toISOString().split('T')[0];
      this.customerFeedback.controls['wed_date'].setValue(this.wedDate);
    } else {
      var wedDate = new Date(this.customerFeedback.value.wed_date);
      wedDate.setDate(event.target.value);
      this.wedDate = wedDate.toISOString().split('T')[0];
      this.customerFeedback.controls['wed_date'].setValue(this.wedDate);
    }


  }
  wMonthChange(event) {

    var value = event.target.value;
    var min = 1;
    var max = 12;
    if (parseInt(value) < min || isNaN(parseInt(value))) {
      this.customerFeedback.controls['wmon'].setValue(min);
      value = min;
    }
    else if (parseInt(value) > max) {
      this.customerFeedback.controls['wmon'].setValue(max);
      value = max;
    }
    else value = value;

    console.log(event.target.value)
    console.log(this.customerFeedback.value.wmon)

    if (this.customerFeedback.value.wed_date == '') {

      this.wedDate = new Date();
      this.wedDate.setMonth(value - 1);
      this.wedDate = this.wedDate.toISOString().split('T')[0];
      this.customerFeedback.controls['wed_date'].setValue(this.wedDate);
    } else {
      var wedDate = new Date(this.customerFeedback.value.wed_date);
      wedDate.setMonth(value - 1);
      this.wedDate = wedDate.toISOString().split('T')[0];
      this.customerFeedback.controls['wed_date'].setValue(this.wedDate);
    }

  }
  validateEmailChange(emailField) {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (reg.test(emailField.target.value) == false) {
      // alert('Invalid Email Address');
      this.toastr.error('Invalid Email Address!');
      return false;
    }

    return true;

  }
  validateEmail(emailField) {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (reg.test(emailField) == false) {
      // alert('Invalid Email Address');
      // this.toastr.error('Invalid Email Address!');
      return false;
    }

    return true;

  }
  reload() {
    location.reload();
  }

  hourCount(id, e) {
    var keynum;
    if (window.event) { //IE
      keynum = e.keyCode;
    } else if (e.which) {
      keynum = e.which;
    } if (id !== undefined) {
      console.info("hour text box Value : " + id.value);
      console.info("Charcter key press value : " + String.fromCharCode(keynum));

      var currentValue = id.value + String.fromCharCode(keynum);

      console.info("Combine value :" + currentValue);
      if (currentValue > "23") {
        console.info("Then Result is :" + currentValue);
        e.preventDefault();
        return false;
      }
    } else {
      e.preventDefault();
      return false;
    }
  }

  showCheckboxes() {
    var checkboxes = document.getElementById("checkboxes");
    if (!this.expanded) {
      checkboxes.style.display = "block";
      this.expanded = true;
    } else {
      checkboxes.style.display = "none";
      this.expanded = false;
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCountries.filter(option => option.toLowerCase().includes(filterValue));
  }


  nationalityCheck(event) {
    console.log(event.target.value)

    var values = event.target.value;

    var isString = isNaN(values);
    var results = [];
    // this.allNationality

    for (let i = 0; i < this.allNationality.length; i++) {
      if (isString == false) {
        values = values.toLowerCase();
        // values = values.charAt(0).toUpperCase();
        const nameCapitalized = values.charAt(0).toUpperCase() + values.slice(1)

        console.log(nameCapitalized)

        if (this.allNationality[i].CODE.includes(nameCapitalized) != false) {
          results.push(this.allNationality[i].CODE);
        }
      } else {
        values = values.toLowerCase();
        // values = values.charAt(0).toUpperCase();
        const nameCapitalized = values.charAt(0).toUpperCase() + values.slice(1)

        console.log(nameCapitalized)
        if (this.allNationality[i].DESCRIPTION.includes(nameCapitalized) != false) {
          results.push(this.allNationality[i].DESCRIPTION);
        }
      }

    }
    console.log(results)
    if (results.length == 0) {
      this.customerFeedback.controls['nationality'].setValue('');
    }

  }

  countryCheck(event) {
    console.log(event.target.value)

    var values = event.target.value;
    //  var res=  this.allCountries.filter( obj => Object.keys(obj).some( key => key.indexOf(event.target.value) > -1 ) )
    // console.log(res)
    // var res= this.allCountries.filter(obj => Object.keys(obj).some(key => obj[key].includes(event.target.value)));

    // console.log(res)
    // let obj = this.allCountries.filter(o => o.MOBILECOUNTRYCODE == event.target.value);

    // console.log(isNaN(values))
    var isString = isNaN(values);
    var results = [];
    // this.allCountries

    for (let i = 0; i < this.allCountries.length; i++) {
      if (isString == false) {

        if (this.allCountries[i].MOBILECOUNTRYCODE.includes(values) != false) {
          results.push(this.allCountries[i].MOBILECOUNTRYCODE);
        }
      } else {
        values = values.toLowerCase();
        // values = values.charAt(0).toUpperCase();
        const nameCapitalized = values.charAt(0).toUpperCase() + values.slice(1)

        console.log(nameCapitalized)
        if (this.allCountries[i].DESCRIPTION.includes(nameCapitalized) != false) {
          results.push(this.allCountries[i].DESCRIPTION);
        }
      }

    }
    console.log(results)
    if (results.length == 0) {
      this.customerFeedback.controls['country'].setValue('');
    }

    // var res= this.allCountries.filter(o => Object.values(o).some(v => v === values));
    // console.log(res)
    // var res = this.allCountries.filter(function(el: '') {
    //   return el.toLowerCase().indexOf(values.toLowerCase()) !== -1
    // })
    // const filterValue = values.toLowerCase();

    // var res = this.allCountries.filter(option => option.includes(filterValue))
    // var res =this._filter(values)
    // var res = this.allCountries.filter((el : any) => el.toLowerCase().indexOf(values.toLowerCase()) !== -1)
    // var templogRecord = []
    // var res = this.allCountries.filter((item: templogRecord) => {
    //   return item.sensor.toLowerCase().includes(values.toLowerCase());
    // });
    // console.log(res)

    // let freelancers = [{name: "Harry", skill: "JavaScript"},{name: "Mark", skill: "Python"},{name: "David", skill:"JavaScript"}];

    // let javascript_freelancers = this.allCountries.filter(function(freelancer) {
    //  console.log( freelancer.includes(values))
    //     return freelancer.MOBILECOUNTRYCODE == values; });

    // console.log(javascript_freelancers);
    // const obj = this.allCountries.filter(obj => Object.values(event.target.value).some(val => val.includes(search)));
    //   if(event.target.value.length >3){

    //   var name ;
    //   if(obj.length == 0){
    //  name = this.allCountries.filter(o => o.DESCRIPTION == event.target.value);
    // //  console.log('not found')

    //     if(name.length == 0){
    //       this.customerFeedback.controls['country'].setValue('');
    //       console.log('not found')
    // console.log(name);

    //     }
    //   }

    //   }

    // console.log(obj);
  }
  formSubmit() {
    console.log( this.cDateFormat(this.customerFeedback.value.birth_date));
    console.log( this.convertDateToMDY(this.customerFeedback.value.birth_date));
    // this.customerFeedback.controls['wed_date'].setValue('1900-01-01');
    // console.log( this.cDateFormat(this.customerFeedback.value.wed_date));

    console.log(this.bMonth)
    console.log(this.userCode)
    console.log(this.customerFeedback.value.marital_status)
    // console.log(this.customerFeedback.value.country)

    // console.log(this.aboutVal)

    // var test =this.customerFeedback.value.about.join('#');
    // console.log(test)
    var abouts, aboutRes;
    if (this.customerFeedback.value.about != '') {
      abouts = this.customerFeedback.value.about.map(function (item) { return item.item_id; });
      aboutRes = abouts.join('#');

    } else {
      aboutRes = '';
    }
    // console.log(abouts)
    var preComs, preffredCom;
    if (this.customerFeedback.value.pre_com != '') {
      preComs = this.customerFeedback.value.pre_com.map(function (item) { return item.item_id; });
      preffredCom = preComs.join('#');

    } else {
      preffredCom = '';
    }
    // console.log(preComs)

    // console.log(this.customerFeedback.value.about)
    // console.log(this.customerFeedback.value.pre_com)
    // var aboutRes =this.customerFeedback.value.about.join('#');
    // var preffredCom =this.customerFeedback.value.pre_com.join('#');

    // var aboutRes = this.aboutVal.join('#');
    // var preffredCom = this.precomVal.join('#');

    console.log(aboutRes)
    console.log(preffredCom)
    // var emailResult =  this.validateEmail(this.customerFeedback.value.email);
    // console.log(emailResult);
    // console.log(emailResult.);
    // emailResult.then((result) => {
    // });

    // console.log(this.newCustomer)
    // is-invalid
    if (this.customerFeedback.value.mobile === "") {
      this.mobileClass = "is-invalid";
    } else {
      this.mobileClass = "";

    }

    if (this.customerFeedback.value.username === "") {
      this.userClass = "is-invalid";
    } else {
      this.userClass = "";

    }

    /* if (this.customerFeedback.value.email === "") {
       this.emailClass = "is-invalid";
     } else {
       this.emailClass = "";

     }*/
    if (this.customerFeedback.value.country === "") {
      this.codeClass = "is-invalid";
    } else {
      this.codeClass = "";

    }



    /* if (this.customerFeedback.value.birth_date == "") {
       this.dobClass = "is-invalid";
     } else {
       this.dobClass = "";

     }*/


    if (this.customerFeedback.value.looking_for == "True") {
      this.customerFeedback.controls['reason_non_select'].setValue(' ');

    }
    if (this.customerFeedback.value.marital_status == "Married") {
      // this.customerFeedback.controls['reason_non_select'].setValue(' ');

      /*if (this.customerFeedback.value.wed_date == "") {
        this.wedClass = "is-invalid";
      } else {
        this.wedClass = "";

      }*/

    }


    if (this.customerFeedback.value.country != "" && this.customerFeedback.value.username && this.customerFeedback.value.mobile) {
      // if(this.customerFeedback.value.country != "" && this.customerFeedback.value.username && this.customerFeedback.value.mobile && this.customerFeedback.value.email){
      if (this.customerFeedback.value.email === "") {
        emailResult = true;
      } else {
        var emailResult = this.validateEmail(this.customerFeedback.value.email);
      }


      //var emailResult = this.validateEmail(this.customerFeedback.value.email);
      if (emailResult == true) {
        //   return;
        // }
        // console.log(emailResult);

        let value = this.allCountries.find(i => i.MOBILECOUNTRYCODE == this.customerFeedback.value.country);
        this.countryFull = value['DESCRIPTION'];

        if (this.customerFeedback.value.birth_date == "") {
          this.customerFeedback.controls['birth_date'].setValue('1900-01-01');
          this.bMonth = '1';
          this.bDay = '1';
        }

        if (this.customerFeedback.value.wed_date == "") {
          this.customerFeedback.controls['wed_date'].setValue('1900-01-01');
          this.wMonth = '1';
          this.wDay = '1';
        }

        if (this.customerFeedback.value.marital_status == 'Single' || this.customerFeedback.value.marital_status == '') {

          // this.wedDate = new Date( '0001-01-01T00:00:00');
          // this.wedDate  =  this.wedDate.toISOString().split('T')[0];

          this.customerFeedback.controls['wed_date'].setValue('1900-01-01');
        }

        let postData = {
          "BRANCH_CODE": this.branch,
          "CODE": this.userCode,
          "POSCUSTPREFIX": this.customerFeedback.value.salutation,
          "NAME": this.customerFeedback.value.username,
          "COMPANY": ' ',
          "MARITAL_ST": this.customerFeedback.value.marital_status,
          "GENDER": this.customerFeedback.value.gender,
          "DATE_OF_BIRTH": this.cDateFormat(this.customerFeedback.value.birth_date),
          "WED_DATE": this.cDateFormat(this.customerFeedback.value.wed_date),
          // "DATE_OF_BIRTH": this.convertDateToMDY(this.customerFeedback.value.birth_date) + 'T00:00:00.000Z',
          // "WED_DATE": this.convertDateToMDY( this.customerFeedback.value.wed_date ) + 'T00:00:00.000Z',
          "EMAIL": this.customerFeedback.value.email,
          "COUNTRY": this.countryFull,
          "COUNTY_CODE": this.customerFeedback.value.country,
          MOBILE: this.customerFeedback.value.mobile.toString(),
          TELNO: this.customerFeedback.value.tel1.toString(),
          "HOW_U_KNOW_US": aboutRes,
          "OCCUPATION": this.customerFeedback.value.occupation || '',
          "SHOWROOM_ACCESS": this.customerFeedback.value.showroom_access,
          "PRD_RANGE": this.customerFeedback.value.product_range,
          "QC_DESIGN": this.stringToBoolean(this.customerFeedback.value.quality_design),
          "SERVICE_QC": this.customerFeedback.value.overall_service,
          "STAFF_ATTITUDE": this.customerFeedback.value.staff_behaviour,
          "SHOROOM_DISPLAY": this.customerFeedback.value.showroom_amb,
          "NONSELECTION_REASON": this.customerFeedback.value.reason_non_select,
          ISSATISFIED: this.stringToBoolean(this.customerFeedback.value.shop_exe),
          "DOYOU_GET_ITEM": this.customerFeedback.value.looking_for || '',
          "SUGGESTION_FOR_IMPROVEMENT": this.customerFeedback.value.suggess_impro,
          "DETAIL_REMARK_NONSELECTION": this.customerFeedback.value.detailed_remark,
          BIRTH_MONTH: this.bMonth.toString(),
          BIRTH_DAY: this.bDay.toString(),
          WED_MONTH: this.wMonth.toString(),
          WED_DAY: this.wDay.toString(),
          "NATIONALITY": this.customerFeedback.value.nationality,
          "PREF_COM_PLATFORM": preffredCom,
          "WUPMOBILECODE": this.customerFeedback.value.telcode,
          "NEW_CUSTOMER": this.newCustomer,


          // new fields
          "REASON_OF_PURCHASE": "",
          "AGE_GROUP": "",
          "GIFT_PURCHASED_FOR": "",
          "PURCHASE_OCCASION": "",
          "NEXT_VISIT": "",
          "FESTIVAL_EID": false,
          "FESTIVAL_CHRISTMAS": false,
          "FESTIVAL_DIWALI": false,
          "FESTIVAL_NATIONALDAY": false,
          "FESTIVAL_ONAM": false,
          "FESTIVAL_PONGAL": false,
          "FESTIVAL_NEWYEAR": false,
          "DETAILS_JOHARA": "",
          "DETAILS_FARAH": "",
          "DETAILS_JAWAHERALSHARQ": "",
          "LOOKING_FOR": "",
          "OVERALL_EXP": "",
          "CUSTOMER_TYPE": ""
        }

        console.log(postData);
        this.suntechCustomerFb.postCustomerFeedback(postData)
          .subscribe((result) => {
            console.log(result)
            if (result['status'] == "Success") {
              // alert('Feedback Submitted Successfully');
              this.toastr.success('Feedback Submitted Successfully', 'Success!', {
                timeOut: 3000,
              });

              // alert(result['Message']);
              setTimeout(() => {
                // location.reload();
                // this.router.navigate(['feedback'])
                // this.customerFeedback.reset();
                let currentUrl = this.router.url;
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  this.router.navigate([currentUrl]);
                });
                console.log(this.bMonth)
                console.log(this.userCode)
              }, 1500);

            } else {
              this.toastr.error('Fill The Valid Details');
            }
          });
      } else {
        // alert('Invalid Email Address');
        this.toastr.clear();
        this.toastr.error('Invalid Email Address');


      }
    } else {
      // alert('Fill All Fields');
      this.toastr.clear();
      this.toastr.error('Fill Required Fields');

    }



  }

  stringToBoolean = (string) => string != undefined && string != null ? string.toString().toLowerCase() == 'false' ? false : true : false;

  // convert date to mm-dd-yyy format
  convertDateToMDY(data: any) {
    if (data != '' && data != null) {
      const date = new Date(data);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      const formattedDate = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;
      return formattedDate;
    } else {
      return '';
    }

  }

  cDateFormat(value: any) {
    return this.convertDateWithTimeZero(this.datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ss'));
  }
  convertDateWithTimeZero(date: any) {
    return date.split('T')[0] + 'T00:00:00.000Z';
  }

}
