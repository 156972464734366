

 <!-- <div class="imageLogo">
    <img src="../../../assets/imgs/feedback-banner.jpg" alt="" class="bg-image">
</div> -->
<div class="login-container">

<div class="card">
    <h4 class="card-header text-center" > <b> Login </b></h4>
    <div class="card-body">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="username"> <b>Username</b> </label>
                <input type="text" formControlName="username" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" (change)="usernameChange($event)" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
            </div>

            <div class="form-group">

                <label for="branch"> <b> Branch</b></label>

                <ng-multiselect-dropdown
                [ngClass]="{ 'is-invalid': submitted && f.branch.errors }"
                placeholder="Select Branch"
                formControlName="branch"
                [settings]="dropdownSettings"
                [data]="branchList"
              >
              </ng-multiselect-dropdown>
              <div *ngIf="submitted && f.branch.errors" class="invalid-feedback">
                <div *ngIf="f.branch.errors.required">Branch is required</div>
            </div>
            </div>

            <div class="form-group">
                <label for="password"> <b> Password </b></label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
            </div>

            <div class="form-group text-center">
                <button [disabled]="loading" class="btn btn-success">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <!-- <a routerLink="../register" class="btn btn-link">Register</a> -->
            </div>
            <div class="text-center" *ngIf="resetPassDis" >
                <a routerLink="../reset-password" class="btn btn-link "  >Reset Password</a>
            </div>

        </form>
    </div>
</div>
</div>
