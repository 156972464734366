import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerFeedbackService } from '../../customer-feedback.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  [x: string]: any;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  branchList :Array<any> = [];
  resetPassDis: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private suntechCustomerFb: CustomerFeedbackService,
    private toastr: ToastrService


  ) {
    localStorage.setItem('login_username',"" )

    this.dropdownSettings = {
      // this.dropdownSettings:IDropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
    // this.getBranchList();
   }

  get f() { return this.loginForm.controls; }

  getBranchList(username){
    this.suntechCustomerFb.getBranch(username)
    .subscribe((res) => {
      // console.log(res)

      if(res['status'] != "Failed"){
        // this.branchList.push(result['Result']);
        let tmp = [];
        // var result = res['Result'];
        var result = res['response'];
      for(let i=0; i < result.length; i++) {
        tmp.push({ item_id: result[i]['BRANCH_CODE'], item_text:  result[i]['BRANCH_CODE']});
      }
      this.branchList = tmp;

      }
      else{
        this.branchList= [];

           this.toastr.error('Branch List Is Empty', 'Failed', {
          timeOut: 3000,
        });
      }
    });
  }

  usernameChange(event){

    // console.log(event.target.value)
    this.loginForm.controls['branch'].setValue('');
    this.loginForm.controls['password'].setValue('');

    localStorage.setItem('login_username',event.target.value )

    this.suntechCustomerFb.usernameCheck(this.f.username.value)
    .subscribe((result) => {
      // console.log(result)

      if(result['status'] == 'Success'){
      // console.log(result['Result'][0]['USERSNAME'])

        // this.getBranchList(result['Result'][0]['USERSNAME'])
        this.getBranchList(this.f.username.value)

        // this.getBranchList(result['response'][0]['USERSNAME'])


        // if(result['Result'][0]['Login_Password_DOTNET'] != 0){
        if(result['response'][0]['Login_Password_DOTNET'] != 0){

          this.resetPassDis = false;
        }else{
          this.resetPassDis = true;

        }
      }else{
        this.branchList= [];

        this.toastr.error('Invalid Username.', 'Failed !', {
          timeOut: 2000,
        });
      }
    });

  }



  onSubmit() {
    // console.log(this.f.branch.value)
    // console.log(this.f.branch.value[0]['item_id']);

    this.submitted = true;
    // userLogin
    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    // console.log(this.f.username.value)
    this.loading = true;
    this.suntechCustomerFb.userLogin(this.f.username.value, this.f.password.value)
      .subscribe((result) => {
        // console.log(result)
        this.loading = false

        if(result['status'] != "Failed"){
          // this.branchList.push(result['Result']);

          localStorage.setItem('branchCode', this.f.branch.value[0]['item_id'])
          localStorage.setItem('login_username',this.f.username.value)

          localStorage.setItem('loggedIn', 'yes')


          this.toastr.success('Logged In.', 'Success!', {
            timeOut: 1500,
          });

          setTimeout(() => {
            this.router.navigate(['feedback'])
          }, 1200);
        }
        else{
          // this.branchList= [];

             this.toastr.error('Invalid Username & Password.', 'Failed !', {
            timeOut: 3000,
          });
        }
      });
}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      branch: ['', Validators.required]
  });
  }

}
