import { Injectable } from '@angular/core';

import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { ApiUrl } from '../../src/apiUrl';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CustomerFeedbackService {

  apiurl: any;
  // apiurl=new ApiUrl();


  // constructor(http) {
  //     console.log(this.apiurl)
  //     this.http = http;

  // baseUrl: any = '';
  // baseUrl: any = 'https://angulardata.sunwebapps.com/api/';
  // baseUrl: any = 'http://20.46.145.227:89/api/';

  // baseUrl: any = 'https://feedbackdata.bhimagold.ae/api/';

  // baseUrl: any = 'http://192.168.110.5:88/api/'; // bhima test db url

  baseUrl: any = 'http://185.56.89.191:98/api/'; // navrang test db url


  constructor(
    private http: HttpClient,
  ) {
    // this.baseUrl = environment.baseUrl
    // console.log(environment.baseUrl);

      // console.log(apiurl)

      // console.log(ApiUrl)

  }


  getCountry(): Observable<any> {
    return this.http.get(this.baseUrl+'GeneralMaster/GetGeneralMasterList/country%20master');
    // return this.http.get(this.baseUrl+'GeneralMaster/GetGeneralMasterList?TYPES=country%20master');
  }
  getCustomerDetails(number) : Observable<any> {
    return this.http.get(this.baseUrl+'CustomerFeedbackMaster/'+number+'');
    // return this.http.get(this.baseUrl+'CustomerFeedbackMaster?MOBILE='+number+'');

  }
  postCustomerFeedback(postData) : Observable<any> {
    return this.http.post(this.baseUrl+'CustomerFeedbackMaster', postData);

  }

  getNationality(): Observable<any> {
    return this.http.get(this.baseUrl+'GeneralMaster/GetGeneralMasterList/nationality%20master');
    // return this.http.get(this.baseUrl+'GeneralMaster/GetGeneralMasterList?TYPES=NATIONALITY%20MASTER');
  }
  userLogin(username, password){
    return this.http.get(this.baseUrl+'ValidatePassword?strusername='+username+'&strPassword='+password+'');
  }
  usernameCheck(username){
    return this.http.get(this.baseUrl+'UserDetailNetMaster?USERNAME='+username+'');
  }
  getBranch(username){
    return this.http.get(this.baseUrl+'UseBranchNetMaster?USERNAME='+username+'');
    // return this.http.get(this.baseUrl+'BranchMaster')

  }
  savePassword(username, password){
    return this.http.put(this.baseUrl+'ValidatePassword/UpdatePassword?strusername='+username+'&strPassword='+password+'','');
  }

}
