<div class="container containerstyle">
  <div class="text-center">
    <!-- <img src="../../../assets/imgs/feedback-banner.jpg" alt="" class="bg-image" /> -->
    <img src="../../../assets/imgs/logo.png" alt="" class="bg-image" />

  </div>

  <form [formGroup]="customerFeedback" novalidate>
    <!-- <form [formGroup]="customerFeedback" (ngSubmit)="formSubmit()"> -->

    <div class="row p-2">
      <div class="col-lg-6 col-md-6">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <th rowspan="2">
                Mobile No<span style="color: #ff0000">*</span>
              </th>
              <!-- <th>ISD Code</th> -->
              <!-- <th></th> -->
              <!-- <th>Mobile Number <span style="color:#ff0000">*</span></th> -->
            </tr>
            <tr>
              <td>
                <input list="countrys" class="form-control {{ codeClass }} cap" name="country" id="country"
                  formControlName="country" (change)="countryChanage($event)" (focusout)="changeCode()"
                  placeholder="ISD Code" (keyup)="countryCheck($event)" />

                <datalist id="countrys">
                  <!-- <option *ngFor="let country of allCountries" >{{country.MOBILECOUNTRYCODE }} - {{country.DESCRIPTION }}</option> -->
                  <option *ngFor="let country of allCountries" [value]="country.MOBILECOUNTRYCODE"
                    label="{{ country.DESCRIPTION }}">
                    {{ country.DESCRIPTION }}
                  </option>
                </datalist>
              </td>
              <td>
                <input type="number" class="form-control {{ mobileClass }}" name="mobile" id="mobile" pattern="[0-9]"
                  min="1" formControlName="mobile" (focusout)="getCustomerDetails($event)"
                  (change)="numberChange($event)" (keyup)="parseI($event)" required />
              </td>
            </tr>
            <tr>
              <td></td>
              <td colspan="2">
                <input type="checkbox" id="samenum" name="samenum" value="samenum" (click)="clickSame($event)" /> &nbsp;
                <label for="samenum"> Use for whatsapp</label>
              </td>
            </tr>
            <tr>
              <th>WhatsApp Number:</th>
              <td style="width: 35%">
                <input list="telcodes" class="form-control" name="telcode" id="telcode" formControlName="telcode"
                  (change)="chanageCountryCode($event)" (keyup)="countryCodeTel($event)" placeholder="ISD Code" />
                <datalist id="telcodes">
                  <option *ngFor="let country of allCountries" [value]="country.MOBILECOUNTRYCODE"
                    label="{{ country.DESCRIPTION }}">
                    {{ country.DESCRIPTION }}
                  </option>
                </datalist>
              </td>
              <td colspan="2">
                <input type="number" class="form-control" name="tel1" id="tel1" formControlName="tel1" min="1"
                  (keyup)="parseW($event)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-6 col-md-6">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <th>Name <span style="color: #ff0000">*</span></th>
              <td>
                <select name="salutation" id="salutation" formControlName="salutation" class="form-control">
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                </select>
              </td>
              <td colspan="2">
                <input type="text" class="form-control {{ userClass }}" (focusout)="changeName()"
                  formControlName="username" name="username" id="username" required />
              </td>
            </tr>

            <tr>
              <th>Email </th>
              <td colspan="3">
                <input type="email" class="form-control {{ emailClass }}" (focusout)="changeEmail()"
                  (change)="validateEmailChange($event)" formControlName="email" name="email" id="email" />
              </td>
            </tr>

            <tr>
              <th>Occupation</th>
              <td colspan="3">
                <input type="text" class="form-control" name="occupation" formControlName="occupation"
                  id="occupation" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row p-2">
      <div class="col-lg-6 col-md-6">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <th>Gender</th>
              <td>
                <select class="form-control" name="gender" id="gender" formControlName="gender"
                  (change)="changeGender($event)">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>Marital Status</th>
              <td>
                <select class="form-control" name="marital_status" id="marital_status" formControlName="marital_status">
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>Nationality</th>
              <td>
                <input list="nationalitys" class="form-control" name="nationality" id="nationality"
                  formControlName="nationality" placeholder="Nationality" (keyup)="nationalityCheck($event)" />

                <datalist id="nationalitys">
                  <option *ngFor="let nationality of allNationality" [value]="nationality.CODE"
                    label="{{ nationality.DESCRIPTION }}">
                    {{ nationality.DESCRIPTION }}
                  </option>
                </datalist>
              </td>
            </tr>
            <!-- <tr>
                            <td>
                                <select class="form-control" name="gender" id="gender" formControlName="gender" (change)="changeGender($event)">
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select> -->
            <!-- <div class="form-check">
                                    <input class="form-check-input" type="radio" value="Male" name="gender" id="male" formControlName="gender" (change)="changeGender($event)" />
                                    <label class="form-check-label" for="male">
                                        Male
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" value="Female" name="gender" id="female" formControlName="gender" (change)="changeGender($event)">
                                    <label class="form-check-label" for="female">
                                        Female
                                    </label>
                                </div> -->
            <!-- <div class="form-check" *ngIf="this.gender_male == 'checked'">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" (change)="changeGender('Male')" checked />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Male
                                    </label>
                                </div> -->
            <!-- <div class="form-check" *ngIf="this.gender_male != 'checked'">
                                    <input class="form-check-input" type="radio" name="gender" id="male" (change)="changeGender('Male')" />
                                    <label class="form-check-label" for="male">
                                        Male
                                    </label>
                                </div> -->
            <!-- <div class="form-check" *ngIf="this.gender_female == 'checked'">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (change)="changeGender('Female')" checked>
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Female
                                    </label>
                                </div> -->
            <!-- <div class="form-check" *ngIf="this.gender_female != 'checked'">
                                    <input class="form-check-input" type="radio" name="gender" id="female" (change)="changeGender('Female')">
                                    <label class="form-check-label" for="female">
                                        Female
                                    </label>
                                </div> -->
            <!-- </td> -->
            <!-- Marital Status -->
            <!-- <td>
                                <select class="form-control" name="marital_status" id="marital_status" formControlName="marital_status">
                                    <option value="Married">Married</option>
                                    <option value="Single">Single</option>
                                </select> -->
            <!-- <div class="form-check">
                                    <input class="form-check-input" type="radio" name="marital_status" id="married" value="Married" formControlName="marital_status">
                                    <label class="form-check-label" for="married">
                                        Married
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="marital_status" id="single" value="Single" formControlName="marital_status">
                                    <label class="form-check-label" for="single">
                                        Single
                                    </label>
                                </div> -->
            <!-- </td>
                        </tr> -->
          </tbody>
        </table>
      </div>
      <div class="col-lg-6 col-md-6">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <th>Birth Date
                <!--<span style="color: #ff0000">*</span>-->
              </th>
              <td>
                <input type="date" class="form-control {{ dobClass }}" name="birth_date" formControlName="birth_date"
                  id="birth_date" value="{{ dob_date }}" (focusout)="changeDob()" [max]="curt_date"
                  (change)="birthChange($event)" />
              </td>
              <td style="width: 40%">
                <input class="form-control maxDate" type="number" placeholder="Day" value="{{ this.bDay }}"
                  formControlName="bday" (keyup)="bDayChange($event)" min="1" max="31" (keypress)="wDayPress($event)" />
              </td>
              <!-- <td style="width: 40%;"><input class="form-control maxDate" type="number" placeholder="Day" value="{{this.bDay}}" onkeyup="bDayChange($event)"  (keyup)="bDayChange($event)" min="1" max="31" (keypress)="wDayPress($event)"> </td> -->
              <td style="width: 65%">
                <input class="form-control" type="number" placeholder="Month" value="{{ this.bMonth }}"
                  formControlName="bmon" (keyup)="bMonthChange($event)" min="1" max="12" />
              </td>
              <!-- <td >
                                <div class="form-floating ">
                                    <input type="number" class="form-control" id="dd" placeholder="DD" min="1" max="31" value="{{this.bDay}}">
                                    <label for="dd">Day</label>
                                </div>
                            </td>

                            <td >
                                <div class="form-floating ">

                                <input type="number" class="form-control" id="mm" placeholder="MM"  min="1" max="12" value="{{this.bMonth}}">
                                    <label for="mm">Month</label>
                                </div>

                            </td> -->
            </tr>

            <tr *ngIf="
                this.customerFeedback.value.marital_status != 'Single' &&
                this.customerFeedback.value.marital_status != ''
              ">
              <th>Wedding Date
                <!--<span style="color: #ff0000">*</span>-->
              </th>
              <td>
                <input type="date" class="form-control {{ wedClass }}" name="wed_date" id="wed_date"
                  formControlName="wed_date" [max]="curt_date" (change)="wedChange($event)" />
              </td>
              <td style="width: 40%">
                <input class="form-control maxDate" type="number" placeholder="Day" value="{{ this.wDay }}"
                  formControlName="wday" (keyup)="wDayChange($event)" min="1" max="31" />
              </td>
              <td style="width: 65%">
                <input class="form-control" type="number" placeholder="Month" value="{{ this.wMonth }}"
                  formControlName="wmon" (keyup)="wMonthChange($event)" min="1" max="12" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row p-2">
      <div class="col-lg-6 col-md-6">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <th>How did you know about us?</th>
              <td>
                <!-- <div class="multiselect">
                                    <div class="selectBox " (click)="showCheckboxes()">
                                      <select class="form-control">
                                        <option>Select an options</option>
                                      </select>
                                      <div class="overSelect"></div>
                                    </div>
                                    <div id="checkboxes">
                                      <label for="one">
                                        <input type="checkbox" id="one" />First checkbox</label>
                                      <label for="two">
                                        <input type="checkbox" id="two" />Second checkbox</label>
                                      <label for="three">
                                        <input type="checkbox" id="three" />Third checkbox</label>
                                    </div>
                                  </div> -->
                <!-- [(ngModel)]="selectedItems" -->

                <ng-multiselect-dropdown placeholder="Select options" formControlName="about"
                  [settings]="dropdownSettings" [data]="dropdownList" (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)" (onDeSelect)="onAboutDeSelect($event)"
                  (onDeSelectAll)="onAboutDeSelectAll($event)">
                  <ng-template #optionSelectedTemplate optionSelectedTemplate let-option="option" let-id="item_id">
                    {{ option }}
                  </ng-template>
                </ng-multiselect-dropdown>

                <!-- <select class="form-control" name="about" formControlName="about" id="about" multiple>
                                            <option value="TV">Television</option>
                                            <option value="RA">Radio</option>
                                            <option value="NE">Print Ad</option>
                                            <option value="SM">Social Media</option>
                                            <option value="HO">Hoarding</option>
                                            <option value="FF">Friends Or Relative</option>
                                            <option value="ON">Online</option>
                                            <option value="OU">Outdoor</option>
                                            <option value="OT">Others</option>
                                    </select> -->

                <!-- <ng-select2 [data]="cars"></ng-select2> -->

                <!-- <ng-select >
                                <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
                             </ng-select> -->
                <!-- <mat-form-field appearance="fill">
                                <mat-label>Toppings</mat-label>
                                <mat-select [formControl]="toppings" multiple>
                                  <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                </mat-select>
                              </mat-form-field> -->
                <!-- <input list="abouts" class="form-control" name="about" formControlName="about" id="about">

                                <datalist id="abouts">
                                    <option value="Television">
                                    <option value="Radio">
                                    <option value="Print Ad">
                                    <option value="Social Media">
                                    <option value="Hoarding">
                                    <option value="Friends Or Relative">
                                    <option value="Others">
                                </datalist> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-6 col-md-6">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <th colspan="2">Do you get, what you are looking for?</th>
              <td>
                <select class="form-control" name="looking_for" id="looking_for" formControlName="looking_for">
                  <option value="True" selected>Yes</option>
                  <option value="False">No</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-lg-12 col-md-12">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr *ngIf="this.customerFeedback.value.looking_for != 'True'">
              <th>Reason for non selection</th>
              <td>
                <select class="form-control" name="reason_non_select" formControlName="reason_non_select"
                  id="reason_non_select">
                  <option value="Item not available">Item not available</option>
                  <option value="Making charges are high">
                    Making charges are high
                  </option>
                  <option value="Staff behaviour">Staff behaviour</option>
                  <option value="Others">Others</option>
                </select>
              </td>
            </tr>

            <tr>
              <th style="width: 48.5%">Remarks</th>
              <td>
                <textarea class="form-control" rows="1" name="detailed_remark" id="detailed_remark"
                  formControlName="detailed_remark"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row p-2">
      <div class="col-lg-12 col-md-12">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <th style="width: 48.5%">
                Are you satisfied with the shopping experience?
              </th>
              <td>
                <select class="form-control" name="shop_exe" id="shop_exe" formControlName="shop_exe" value="Yes">
                  <option value="True" selected>Yes</option>
                  <option value="False">No</option>
                </select>
                <!-- <input list="shop_exes" class="form-control" name="shop_exe" id="shop_exe" formControlName="shop_exe">

                                <datalist id="shop_exes">
                                    <option value="Television">
                                    <option value="Radio">
                                    <option value="Print Ad">
                                    <option value="Social Media">
                                    <option value="Hoarding">
                                    <option value="Friends Or Relative">
                                    <option value="Others">
                                </datalist> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="col-lg-4 col-md-4">


            </div> -->
    </div>

    <div class="row p-2">
      <div class="col-lg-6 col-md-6">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <th>Showroom accessibility</th>
              <td>
                <select class="form-control" name="showroom_access" id="showroom_access"
                  formControlName="showroom_access">
                  <option value="Excellent">Excellent</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
                <!-- <input list="showroom_accesss" class="form-control" name="showroom_access" id="showroom_access" formControlName="showroom_access">

                                <datalist id="showroom_accesss">
                                    <option value="Excellent">
                                    <option value="Good">
                                    <option value="Satisfactory">
                                    <option value="Poor">
                                </datalist> -->
              </td>
            </tr>
            <tr>
              <th>Product range and availability</th>
              <td>
                <select class="form-control" name="product_range" id="product_range" formControlName="product_range">
                  <option value="Excellent">Excellent</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
                <!-- <input list="product_ranges" class="form-control" name="product_range" id="product_range" formControlName="product_range">

                                <datalist id="product_ranges">
                                    <option value="Excellent">
                                        <option value="Good">
                                        <option value="Satisfactory">
                                        <option value="Poor">
                                </datalist> -->
              </td>
            </tr>

            <tr>
              <th>Do you like quality and design of product ?</th>
              <td>
                <!-- <input list="quality_designs" class="form-control" name="quality_design" id="quality_design" formControlName="quality_design"> -->
                <select class="form-control" name="quality_design" id="quality_design" formControlName="quality_design"
                  value="Yes">
                  <option value="True" selected>Yes</option>
                  <option value="False">No</option>
                </select>

                <!-- <datalist id="quality_designs">
                                    <option value="Excellent">
                                        <option value="Good">
                                        <option value="Satisfactory">
                                        <option value="Poor">
                                </datalist> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-6 col-md-6">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <th>Showroom display and ambience</th>
              <td>
                <select class="form-control" name="showroom_amb" id="showroom_amb" formControlName="showroom_amb">
                  <option value="Excellent">Excellent</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
                <!-- <input list="showroom_ambs" class="form-control" name="showroom_amb" id="showroom_amb" formControlName="showroom_amb">

                                <datalist id="showroom_ambs">
                                    <option value="Excellent">
                                        <option value="Good">
                                        <option value="Satisfactory">
                                        <option value="Poor">
                                </datalist> -->
              </td>
            </tr>
            <tr>
              <th>Staff behaviour and attitude</th>
              <td>
                <select class="form-control" name="staff_behaviour" id="staff_behaviour"
                  formControlName="staff_behaviour">
                  <option value="Excellent">Excellent</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
                <!-- <input list="staff_behaviours" class="form-control" name="staff_behaviour" id="staff_behaviour" formControlName="staff_behaviour">

                                <datalist id="staff_behaviours">
                                    <option value="Excellent">
                                        <option value="Good">
                                        <option value="Satisfactory">
                                        <option value="Poor">
                                </datalist> -->
              </td>
            </tr>

            <tr>
              <th>Overall service quality</th>
              <td>
                <select class="form-control" name="overall_service" id="overall_service"
                  formControlName="overall_service">
                  <option value="Excellent">Excellent</option>
                  <option value="Good">Good</option>
                  <option value="Fair">Fair</option>
                  <option value="Poor">Poor</option>
                </select>
                <!-- <input list="overall_services" class="form-control" name="overall_service" id="overall_service" formControlName="overall_service">

                                <datalist id="overall_services">
                                    <option value="Excellent">
                                        <option value="Good">
                                        <option value="Satisfactory">
                                        <option value="Poor">
                                </datalist> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row p-2">
      <div class="col-lg-12 col-md-12">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <th style="width: 48.5%">Preferred communication platform</th>
              <td>
                <!-- <select class="form-control" name="pre_com" id="pre_com" formControlName="pre_com" multiple>
                                    <option value="EM" >Email</option>
                                    <option value="WH" >WhatsApp</option>
                                    <option value="SM" >SMS</option>
                                    <option value="PH" >Telephone</option>
                                    <option value="NO" >No</option>
                                </select> -->
                <ng-multiselect-dropdown placeholder="Select options" formControlName="pre_com"
                  [settings]="dropdownSettingsPrecom" [data]="preComList" (onDeSelect)="onDeSelect($event)"
                  (onDeSelectAll)="onDeSelectAll($event)" (onSelect)="onPreComItemSelect($event)"
                  (onSelectAll)="onPreComSelectAll($event)">
                </ng-multiselect-dropdown>
                <!-- <input list="shop_exes" class="form-control" name="shop_exe" id="shop_exe" formControlName="shop_exe">

                                <datalist id="shop_exes">
                                    <option value="Television">
                                    <option value="Radio">
                                    <option value="Print Ad">
                                    <option value="Social Media">
                                    <option value="Hoarding">
                                    <option value="Friends Or Relative">
                                    <option value="Others">
                                </datalist> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row p-2">
      <div class="col-lg-12 col-md-12">
        <table data-toggle="table" class="table table-bordered">
          <tbody>
            <tr>
              <td>
                <div style="text-align: center">
                  <b> <label>Suggessions for improvements …</label> </b>
                </div>
                <textarea class="form-control" rows="5" name="suggess_impro" id="suggess_impro"
                  formControlName="suggess_impro"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="col-lg-4 col-md-4">


            </div> -->
    </div>

    <div style="padding-bottom: 20px">
      <div style="text-align: right; padding: 10px">
        <button class="btn btn-warning" (click)="reload()">Reset</button> &nbsp;
        <!-- <button class="btn btn-success" type="submit" >Submit</button> -->
        <button class="btn btn-success" (click)="formSubmit()">Submit</button>
      </div>
    </div>
  </form>
</div>
