

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerFeedbackService } from '../../customer-feedback.service';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  [x: string]: any;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  branchList :Array<any> = [];
  resetPassDis: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private suntechCustomerFb: CustomerFeedbackService,
    private toastr: ToastrService


  ) {




   }

  get f() { return this.loginForm.controls; }


  onSubmit() {

    if(this.f.password.value == this.f.confirmPassword.value){

    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    console.log(this.f.username.value)
    this.loading = true;
    this.suntechCustomerFb.savePassword(this.f.username.value, this.f.password.value)
      .subscribe((result) => {
        console.log(result)
        this.loading = false

        if(result['status'] != "Failed"){
          // this.branchList.push(result['Result']);
          this.toastr.success('Password Updated Successfully.', 'Success!', {
            timeOut: 3000,
          });
          setTimeout(() => {
            this.router.navigate([''])
          }, 1200);
        }
        else{
             this.toastr.error('Invalid Username & Password.', 'Failed !', {
            timeOut: 3000,
          });
        }
      });
    }else{
      this.toastr.error('Password & Confirm Password Does Not Match.', 'Error !', {
        timeOut: 3000,
      });
    }

}

usernameChange(event){

  // this.loading = true;
  console.log(event.target.value)

  localStorage.setItem('login_username',event.target.value )

  this.suntechCustomerFb.usernameCheck(this.f.username.value)
  .subscribe((result) => {
    console.log(result)
    if(result['status'] == 'Success'){

      // this.loading = false;

    }else{

      this.toastr.error('Invalid Username.', 'Failed !', {
        timeOut: 2000,
      });
    }
  });

}
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
  });

  var username =localStorage.getItem('login_username' )
    if(username != "" && username != null && username != undefined)
    this.loginForm.controls['username'].setValue(username);
    // this.f.username
  }

}
